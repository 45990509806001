import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Tag, Tooltip } from "antd";

import { ClusterConfiguration } from "../components/ClusterConfiguration";
import { useRebalance } from "../api/rebalance";
import { useClusterCostsSummary } from "../api/clustercostssummary";
import { isRebalanceComponentReady } from "../utils/rebalance";
import { useClusterCostOptimization } from "../api/clustercostoptimization";
import { ClusterCostOptimization } from "../type/clustercostoptimization";
import { loading } from "../components/Loading";
import CloudPilotSpin from "../components/Spin";
import { getCostSymbol } from "../utils/getsymbol";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { RefreshTriggerProps } from "../type/common";
import CPUCostDescription from "../components/CPUCostDescription";
import BookADemoModal from "../components/BookADemoModalContent";
import { useUser } from "../api/user";
import ClusterConfigurationWarningOverlay from "../components/ClusterConfigurationWarningOverlay";
import OptimizationWarning from "../components/OptimizationWarning";

export default function RebalancePlan({ triggerRefresh }: RefreshTriggerProps) {
    const { clusterID } = useParams();

    const clustercostoptimization = useClusterCostOptimization();
    const clustercostssummary = useClusterCostsSummary();
    const rebalance = useRebalance();
    const user = useUser();

    const [bookADemo, setBookADemo] = useState(false);

    const [clusterOptimization, setclusterOptimization] = useState<ClusterCostOptimization>();
    const [addComponents, setAddComponents] = useState(false);
    const [enableRebalance, setEnableRebalance] = useState(false);
    const [clusterSummary, setClusterSummary] = useState<ClusterCostsSummary>();
    const navigate = useNavigate();

    const [startSavingButtonPending, setStartSavingButtonPending] = useState(false);

    useEffect(() => {
        async function fetchClusterCostsSummary() {
            if (!clusterID) {
                return;
            }
            const { code, message, data } =
                await clustercostssummary.getClusterCostsSummary(clusterID);
            if (code !== 200) {
                console.error("Failed to fetch cluster costs summary:", message);
                return;
            }
            setClusterSummary(data!);
        }
        fetchClusterCostsSummary();
    }, [clusterID]);

    useEffect(() => {
        async function fetchClusterCostOptimization() {
            if (!clusterID) {
                return;
            }
            const { code, message, data } =
                await clustercostoptimization.getClusterCostOptimization(clusterID);
            if (code !== 200) {
                console.error("Failed to fetch cluster costs optimization:", message);
                return;
            }
            setclusterOptimization(data!);
        }
        fetchClusterCostOptimization();
    }, [clusterID])

    useEffect(() => {
        async function fetchClusterRebalanceStatus() {

        }
        fetchClusterRebalanceStatus();
    }, [clusterID])

    function handelEnableRebalance() {
        async function enableRebalance() {
            if (!clusterID) {
                return;
            }
            const { code, message } =
                await rebalance.updateRebalanceConfiguration(clusterID, { enable: true, enableGPUNodePool: null, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null, ecsGeneralNodePoolSpec: null, ecsGPUNodePoolSpec: null, ecsNodeClassSpec: null });
            if (code !== 200) {
                console.error("Failed to enable rebalance:", message);
                return;
            }
            setEnableRebalance(false);
            triggerRefresh();
        }
        enableRebalance();
    }

    async function handleStartRebalance() {
        if (!clusterID) {
            return;
        }

        try {
            setStartSavingButtonPending(true);

            if (!clusterSummary?.demo) {
                const userConfiguration = await user.getUserConfiguration();
                if (userConfiguration.data?.allowSelfDeploy === false) {
                    setBookADemo(true);
                    return;
                }
            }

            const status = await rebalance.getRebalanceStatus(clusterID);
            if (status.code !== 200) {
                console.error("Failed to fetch cluster rebalance status:", status.message);
                return;
            }
            if (!isRebalanceComponentReady(status.data)) {
                setAddComponents(true);
                return
            }
            setEnableRebalance(true);
        } finally {
            setStartSavingButtonPending(false);
        }
    }

    const confirmButton = (
        <Button key="start" type="primary" onClick={() => {
            setConfirmModalFooter(<CloudPilotSpin />);
            handelEnableRebalance();
        }}>
            Confirm
        </Button>
    );
    const [confirmModalFooter, setConfirmModalFooter] = useState(confirmButton);

    if (!clusterOptimization || !clusterSummary) {
        return (
            <loading.RequestLoading />
        );
    }
    const symbol = getCostSymbol(clusterSummary.region);

    if (!clusterOptimization.optimizationNodes) {
        clusterOptimization.optimizationNodes = clusterOptimization.currentNodes;
    }

    const currentNodes = clusterOptimization.currentNodes.reduce((a, b) => a + b.count, 0);
    const optimizedNodes = clusterOptimization.optimizationNodes.reduce((a, b) => a + b.count, 0);

    const currentCPUCores = clusterOptimization.currentNodes.reduce((a, b) => a + b.cpuCores * b.count, 0);
    const optimizedCPUCores = clusterOptimization.optimizationNodes.reduce((a, b) => a + b.cpuCores * b.count, 0);

    const currentRAMGiBs = clusterOptimization.currentNodes.reduce((a, b) => a + b.ramGiBs * b.count, 0);
    const optimizedRAMGiBs = clusterOptimization.optimizationNodes.reduce((a, b) => a + b.ramGiBs * b.count, 0);

    const currentMonthlySpend = clusterOptimization.currentNodes.reduce((a, b) => a + b.monthlyCost * b.count, 0);
    const optimizedMonthlySpend = clusterOptimization.optimizationNodes.reduce((a, b) => a + b.monthlyCost * b.count, 0);

    const currentSpot = clusterOptimization.currentNodes.reduce((a, b) => a + (b.capacityType === "SPOT" ? b.count : 0), 0);
    const optimizedSpot = clusterOptimization.optimizationNodes.reduce((a, b) => a + (b.capacityType === "SPOT" ? b.count : 0), 0);

    const savingPercent = Math.floor((currentMonthlySpend - optimizedMonthlySpend) / currentMonthlySpend * 100);

    const hasClusterOptimizationErrors = clusterOptimization.errors != null && Object.keys(clusterOptimization.errors).length > 0;

    return (
        <div className="w-full h-full">
            <BookADemoModal open={bookADemo} onClose={() => { setBookADemo(false) }} />
            <Modal
                title="Connect to your cluster"
                open={addComponents}
                onCancel={() => { setAddComponents(false) }}
                footer={[
                    <Button key="start" type="primary" onClick={() => {
                        navigate(`/cluster/${clusterID}/computesavings`, { state: { clusterID: clusterID } });
                    }}>
                        Go to install
                    </Button>
                ]}>
                <p className="font-bold mb-2">CloudPilot AI rebalance components are not installed or lost connection!</p>
            </Modal>
            <Modal
                title="Start to rebalance your cluster"
                open={enableRebalance}
                onCancel={() => { setEnableRebalance(false) }}
                footer={confirmModalFooter}>
                <p className="mb-2">Once the rebalance is enabled, CloudPilot will optimize your cluster in real time!</p>
                <p className="mb-2">Click <b>confirm</b> to enable rebalance!</p>
            </Modal>
            <div className="flex flex-col bg-white rounded-lg p-6">
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-row space-x-8">
                        <div className="flex flex-col space-y-4">
                            <h1 className="text-lg font-bold">Rebalance Plan</h1>
                            <span>Cluster rebalance moves your cluster from
                                <Tag className="text-lg font-bold" color="geekblue">{symbol + currentMonthlySpend.toFixed(3)}</Tag>
                                to
                                <Tag className="text-lg font-bold ml-2" color="volcano">{symbol + optimizedMonthlySpend.toFixed(3)}</Tag>
                                , saving
                                <Tag className="text-lg font-bold ml-2" color="volcano">{savingPercent}%</Tag>
                                for you!
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <Tooltip
                            placement="leftTop"
                            title={
                                hasClusterOptimizationErrors
                                    ? 'Please resolve all the optimization warnings before starting rebalancing!'
                                    // https://ant.design/components/tooltip#tooltip-demo-disabled
                                    // When the title is an empty string, the tooltip will be disabled (not hoverable)
                                    : ''
                            }
                            arrow
                        >
                            <Button
                                type="primary"
                                loading={clusterSummary == null || startSavingButtonPending}
                                disabled={!clusterID}
                                onClick={handleStartRebalance}>
                                Start Saving
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className="flex flex-row mt-8 rounded-lg">
                <div className="w-full flex flex-col justify-between bg-white mr-3">
                    <ClusterConfiguration
                        title="Current cluster configuration"
                        currentNodes={currentNodes}
                        currentCPUCores={currentCPUCores}
                        currentMemoryGiBs={currentRAMGiBs}
                        currentMonthlySpend={currentMonthlySpend}
                        currentSpot={currentSpot}
                        symbol={symbol}
                        nodesConfiguration={clusterOptimization.currentNodes} />
                </div>
                <div className="flex items-center w-6">
                    <FontAwesomeIcon icon={faCircleChevronRight} size="2x" color="#2564eb" />
                </div>
                <div className="w-full flex flex-col justify-between bg-white ml-3">
                    {hasClusterOptimizationErrors && !clusterSummary.demo && (
                        <ClusterConfigurationWarningOverlay hasClusterOptimizationErrors={hasClusterOptimizationErrors} />
                    )}
                    <ClusterConfiguration
                        title="Optimized cluster configuration"
                        currentNodes={optimizedNodes}
                        currentCPUCores={optimizedCPUCores}
                        currentMemoryGiBs={optimizedRAMGiBs}
                        currentMonthlySpend={optimizedMonthlySpend}
                        currentSpot={optimizedSpot}
                        nodesConfiguration={clusterOptimization.optimizationNodes}
                        symbol={symbol} />
                </div>
            </div>
            <div className="w-full bg-white mt-8 rounded-lg">
                <OptimizationWarning errs={clusterOptimization.errors} />
            </div>
            <CPUCostDescription />
        </div>
    )
}
