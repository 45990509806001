import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, InputNumber, Switch } from "antd";
import { useRef, useState } from "react";

import { Workload } from "../type/clusterworkload";
import { useRebalance } from "../api/rebalance";

function generateRowID(w: Workload | undefined) {
    if (w === undefined) {
        return "";
    }
    return w.type + "/" + w.namespace + "/" + w.name;
}

interface WorkloadOverviewCardProps {
    clusterID: string;
}

export default function WorkloadsConfigurationList({ clusterID }: WorkloadOverviewCardProps) {
    const rebalance = useRebalance();

    const [rebalanceAbleRowID, setRebalanceAbleRowID] = useState("");
    const [rebalanceAble, setRebalanceAble] = useState(false);
    const [spotFriendlyRowID, setSpotFriendlyRowID] = useState("");
    const [spotFriendly, setSpotFriendly] = useState(false);
    const [minNonSpotReplicasRowID, setMinNonSpotReplicasRowID] = useState("");
    const [minNonSpotReplicas, setMinNonSpotReplicas] = useState(0);

    const actionRef = useRef<ActionType>();

    const columns: ProColumns<Workload>[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            editable: false,
            search: true,
        },
        {
            title: "Namespace",
            dataIndex: "namespace",
            key: "namespace",
            editable: false,
            search: false,
        },
        {
            title: "Workload Type",
            dataIndex: "type",
            key: "type",
            editable: false,
            search: false,
        },
        {
            title: "Replicas",
            dataIndex: "replicas",
            key: "replicas",
            editable: false,
            search: false,
        },
        {
            title: "Rebalanceable",
            dataIndex: "rebalanceAble",
            key: "rebalanceAble",
            search: false,
            valueType: "switch",
            initialValue: true,
            render: (_, record) => {
                return <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={record.rebalanceAble} disabled />
            },
            renderFormItem: (_, { record }) => {
                return <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    defaultChecked={record?.rebalanceAble}
                    onChange={(value) => {
                        setRebalanceAbleRowID(generateRowID(record));
                        setRebalanceAble(value);
                    }}/>
            },
        },
        {
            title: "Spot friendly",
            dataIndex: "spotFriendly",
            key: "spotFriendly",
            search: false,
            render: (_, record) => {
                if (!record.rebalanceAble) {
                    return (<p>-</p>);
                }
                return <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={record.spotFriendly} disabled />
            },
            renderFormItem: (text, { record }, _, action) => {
                return <Switch 
                    checkedChildren="YES" 
                    unCheckedChildren="NO"
                    defaultChecked={record?.spotFriendly || false}
                    onChange={(value) => {
                        setSpotFriendlyRowID(generateRowID(record));
                        setSpotFriendly(value);
                    }}/>;
            },
        },
        {
            title: "Min Non-Spot Replicas",
            dataIndex: "minNonSpotReplicas",
            key: "minNonSpotReplicas",
            search: false,
            valueType: 'digit',
            render: (text, record, _, action) => {
                if (!record.rebalanceAble || !record.spotFriendly) {
                    return (<p>-</p>);
                }
                return text;
            },
            renderFormItem: (text, { record }, _, action) => {
                return <InputNumber
                    defaultValue={record?.minNonSpotReplicas || 0}
                    onChange={(value) => {
                        setMinNonSpotReplicasRowID(generateRowID(record));
                        setMinNonSpotReplicas(value || 0);
                    }}/>
            },
        },
        {
            title: "Operation",
            key: "operation",
            search: false,
            render: (text, record, _, action) => {
                return (
                    <div className="flex flex-row items-center">
                        <Button type="link" size="small" key="edit" disabled={false} onClick={() => {
                            action?.startEditable?.(generateRowID(record));
                        }}>Edit</Button>
                    </div>
                );
            },
            renderFormItem: (text, { record }, _, action) => [
                <Button type="link" size="small" key="savelink" onClick={async () => {
                    if (!record) {
                        return;
                    }

                    let currentRebalanceAble = record.rebalanceAble;
                    let currentSpotFriendly = record.spotFriendly;
                    let currentMinNonSpotReplicas = record.minNonSpotReplicas;
    
                    const currentRowID = generateRowID(record);
                    if (currentRowID === rebalanceAbleRowID) {
                        currentRebalanceAble = rebalanceAble;
                    }
                    if (currentRowID === spotFriendlyRowID) {
                        currentSpotFriendly = spotFriendly;
                    }
                    if (currentRowID === minNonSpotReplicasRowID) {
                        currentMinNonSpotReplicas = minNonSpotReplicas;
                    }
                    const newWorkload: Workload = {
                        name: record.name,
                        type: record.type,
                        namespace: record.namespace,
                        replicas: record.replicas,
                        rebalanceAble: currentRebalanceAble,
                        spotFriendly: currentSpotFriendly,
                        minNonSpotReplicas: currentMinNonSpotReplicas
                    }
                    await rebalance.updateWorkloadRebalanceConfiguration(clusterID, newWorkload);
                    actionRef.current?.reload();
                    action?.cancelEditable?.(generateRowID(record));
                }}>Save</Button>,
                <Button type="link" size="small" key="canclelink" onClick={() => {
                    action?.cancelEditable?.(generateRowID(record));
                }}>Cancel</Button>
            ],
        }
    ]

    return (
        <ProTable<Workload>
            actionRef={actionRef}
            cardBordered
            request={async (params, sort, filter) => {
                const cfg = await rebalance.getWorkloadRebalanceConfiguration(clusterID);
                if (cfg.code !== 200) {
                    console.error("Failed to fetch cluster workload configuration:", cfg.message);
                    return { data: [], success: false };
                }
                const all = cfg.data?.workloads || [];
                if (params.name !== undefined && params.name !== "") {
                    return { data: all.filter((item) => item.name.startsWith(params.name)) || [], success: true };
                }
                return { data: all, success: true };
            }}
            rowKey={(record) => generateRowID(record)}
            columns={columns}
            search={{
                labelWidth: "auto",
            }}
            options={{ reload: true, density: false, setting: false }}
            scroll={{ x: 40 }}
            dateFormatter="string"
            headerTitle="Workload List"
        />
    );
}
